import { Fragment, useMemo } from "react";

import { useQuery } from "@apollo/client";
import Box from "components/Box";
import Divider from "components/Divider";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import useRefetch from "hooks/useRefetch";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import { List, ListContext } from "tpo/List";
import SimpleTable from "tpo/SimpleTable";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import Table, { useTableControls } from "tpo/Table";

const SORT_OPTIONS = [
  {
    label: "Date order (Newest - Oldest)",
    value: "-created"
  },
  {
    label: "Date order (Oldest - Newest)",
    value: "created"
  }
];

export default function OrganisationListCommissions() {
  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { organisation } = useOrganisationContext();
  const { data, loading, refetch: refetchCommissions } = useQuery(
    ORGANISATION_COMMISSIONS_LIST_QUERY,
    {
      variables: {
        page,
        organisation: parseInt(organisation?.id),
        orderBy: sort,
        search: debouncedSearch
      },
      skip: !organisation
    }
  );

  useRefetch(ORGANISATION_COMMISSIONS_LIST_QUERY, refetchCommissions);

  const columns = useMemo(
    () => [
      {
        id: "client",
        label: "Client",
        accessor: ({ user }) => {
          return user ? (
            <Box display="flex" flexDirection="column" gap={10}>
              {!!(user?.firstName && user?.lastName) && (
                <Box
                  fontFamily="gilroyBold"
                  fontSize={16}
                >{`${user?.firstName}, ${user?.lastName}`}</Box>
              )}
              {!!user?.email && <Box fontSize={16}>{user?.email}</Box>}
            </Box>
          ) : null;
        }
      },
      {
        id: "orderDate",
        accessor: ({ checkoutDate }) => new Date(checkoutDate).toLocaleDateString(),
        label: "Order Date"
      },
      {
        id: "id",
        accessor: "id",
        label: "Order No"
      },
      {
        id: "commission",
        accessor: ({ sourceBasket }) => {
          return (
            <Currency
              value={sourceBasket.basketTotalCommission}
              symbol={sourceBasket?.currencySymbol}
            />
          );
        },
        label: "Commission"
      }
    ],
    []
  );

  const items = data?.organisationCommissionsPagination?.items;
  const pageInfo = data?.organisationCommissionsPagination?.pageInfo;

  return (
    <PanelBoxV2 maxWidth={1200}>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: 20,
          borderRadius: 5
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
          Commissions
        </Box>
        <Spacer py={[2, 2, 20]} />
        <SimpleTable
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          sortMenuOpen={sortMenuOpen}
          setSortMenuOpen={setSortMenuOpen}
          pageInfo={pageInfo}
          setPage={setPage}
          sortingOptions={SORT_OPTIONS}
          table={
            <>
              <Box display={["none", "none", "block"]}>
                <Table columns={columns} loading={loading} rows={items} />
              </Box>
              <List items={items} loading={loading} display={["block", "block", "none"]}>
                <ListContext.Consumer>
                  {items =>
                    items?.map(({ user, id, checkoutDate, sourceBasket }) => (
                      <Fragment key={id}>
                        <Box py={2}>
                          <Box py={15}>
                            <Stack gap={20}>
                              <Box display="flex" flexDirection="column">
                                {!!(user?.firstName && user?.lastName) && (
                                  <Box
                                    fontFamily="gilroyBold"
                                    fontSize={16}
                                  >{`${user?.firstName}, ${user?.lastName}`}</Box>
                                )}
                                {!!user?.email && <Box fontSize={16}>{user?.email}</Box>}
                              </Box>
                              <Box>
                                <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                  Order Date
                                </Box>
                                <Box>{new Date(checkoutDate).toLocaleDateString()}</Box>
                              </Box>
                              <Box>
                                <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                  Order No
                                </Box>
                                <Box>{id}</Box>
                              </Box>
                              <Box>
                                <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                  Commission
                                </Box>
                                <Currency
                                  value={sourceBasket.basketTotalCommission}
                                  symbol={sourceBasket?.currencySymbol}
                                />
                              </Box>
                            </Stack>
                          </Box>
                        </Box>
                        <Divider />
                      </Fragment>
                    ))
                  }
                </ListContext.Consumer>
              </List>
            </>
          }
        />
      </PanelBoxV2>
    </PanelBoxV2>
  );
}
