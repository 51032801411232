import { useQuery } from "@apollo/client";
import Box from "components/Box";
import Divider from "components/Divider";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { ORGANISATION_ACCOUNT_BALANCE_QUERY } from "graphql/organisations/queries/commission";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";

export default function OrganisationAccountBalance() {
  const { organisation } = useOrganisationContext();
  const { data, loading } = useQuery(ORGANISATION_ACCOUNT_BALANCE_QUERY, {
    variables: {
      organisation: parseInt(organisation?.id)
    },
    skip: !organisation
  });

  return (
    <PanelBoxV2
      maxWidth={1200}
      inner={{
        bg: "partners",
        px: [20, 20, 40],
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        borderRadius: 5
      }}
    >
      <Box pt={[20, 20, 40]} pb={[30, 30, 60]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
          Your commission balance
        </Box>
        <Spacer py={[2, 2, 20]} />
        {data?.connectedAccountBalances?.map(balance => {
          return (
            <Group justifyContent="space-between">
              <Spacer />
              <Group justifyContent="flex-end" flexDirection="column">
                <Box fontFamily="gilroyMedium" fontSize={18}>
                  Estimated future payout
                </Box>
                <Currency
                  fontSize={48}
                  value={balance.pending}
                  prefix="TOTAL"
                  align="right"
                  symbol={organisation?.currencySymbol}
                />
              </Group>
            </Group>
          );
        })}
        <Spacer py={[2, 2, 20]} />
        <Divider color="black" />
        <Spacer py={[2, 2, 20]} />
        <Box fontFamily="gilroyMedium" fontSize={16}>
          Your Commissions will automatically transferred to your assigned bank account on a monthly
          basis. Any refunds will be removed from your balance.
        </Box>
      </Box>
    </PanelBoxV2>
  );
}
