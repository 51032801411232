import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export default function PayOrderNowFields({ canSubmit }) {
  return (
    <PartnerDashboardBox>
      <Stack gap={[24, 24, 28]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          Payment Details
        </Box>
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          <Box as="p" fontFamily="inherit" fontSize="inherit">
            You still need to{" "}
            <InternalTextLink href="/partners/dashboard/business">
              link your business bank account
            </InternalTextLink>{" "}
            to enable invoice payments.
          </Box>
          <Box as="p" fontFamily="inherit" fontSize="inherit">
            Please pay now to place this order.
          </Box>
        </Box>
      </Stack>
      <Center>
        <ButtonV2
          color="green"
          rightIcon={<ChevronComponent />}
          type="submit"
          disabled={!canSubmit}
        >
          place order
        </ButtonV2>
      </Center>
    </PartnerDashboardBox>
  );
}
