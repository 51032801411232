import { useCallback, useEffect, useRef, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import Box from "components/Box";
import PartnerDashboardBox from "components/boxes/PartnerDashboardBox";
import { useOrganisationBasketContext } from "contexts/organisations/OrganisationBasketContext";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { getPartnerDashboardOrderUrl } from "core/urls";
import RefetchEvent from "events/refetch";
import {
  CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION,
  CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION,
  CREATE_PAYMENT_INVOICE_MUTATION,
  SAVE_SHIPPING_ADDRESS_AT_CHECKOUT_MUTATION
} from "graphql/organisations/mutations";
import { ORGANISATION_COMMISSIONS_LIST_QUERY } from "graphql/organisations/queries/commission";
import { ORGANISATION_ORDERS_LIST_QUERY } from "graphql/organisations/queries/orders";
import { ORGANISATION_STOCK_LIST_QUERY } from "graphql/organisations/queries/stock";
import { NON_FIELD_ERROR_KEY } from "hooks/form/useDjangoGraphqlForm";
import useShippingCountries from "hooks/useShippingCountries";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Group from "tpo/Group";
import NativeSelect from "tpo/NativeSelect";
import Stack from "tpo/Stack";
import ButtonV2, { IndicativeButton } from "v2/Buttons";

import CreateOrderInvoiceFields from "./CreateOrderInvoiceFields";
import PayOrderLaterFields from "./PayOrderLaterFields";
import PayOrderNowFields from "./PayOrderNowFields";

const MUTATIONS_FOR_NEW_ADDRESS = {
  paymentCheckoutSession: {
    mutation: CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION,
    mutationName: "createPaymentCheckoutSessionMutation",
    renderFields: ({ canSubmit }) => <PayOrderNowFields canSubmit={canSubmit} />,
    handleSuccess: ({ formResponseRelated: { data } }) => {
      if (data?.createPaymentCheckoutSessionMutation?.paymentCheckoutSession?.url) {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
        window.location.href = data.createPaymentCheckoutSessionMutation.paymentCheckoutSession.url;
      }
    }
  },
  paymentInvoice: {
    mutation: CREATE_PAYMENT_INVOICE_MUTATION,
    mutationName: "createPaymentInvoiceMutation",
    renderFields: ({ canSubmit }) => <CreateOrderInvoiceFields canSubmit={canSubmit} />,
    handleSuccess: ({ formResponseRelated: { data }, history }) => {
      if (data?.createPaymentInvoiceMutation?.order?.id) {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
        return history.push(
          getPartnerDashboardOrderUrl(data.createPaymentInvoiceMutation.order.id)
        );
      }
    }
  },
  patientOrder: {
    mutation: CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION,
    mutationName: "createPatientOrderFromBasketMutation",
    renderFields: ({ canSubmit }) => <PayOrderLaterFields canSubmit={canSubmit} />,
    handleSuccess: ({ formResponseRelated: { data }, history }) => {
      if (data?.createPatientOrderFromBasketMutation?.order?.id) {
        RefetchEvent.dispatch(ORGANISATION_STOCK_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_ORDERS_LIST_QUERY);
        RefetchEvent.dispatch(ORGANISATION_COMMISSIONS_LIST_QUERY);
        history.push(
          getPartnerDashboardOrderUrl(data.createPatientOrderFromBasketMutation.order.id)
        );
      }
    }
  }
};

function usePaymentFields({ mutations }) {
  const { organisation } = useOrganisationContext();
  const { basket } = useOrganisationBasketContext();

  let mutation = mutations.paymentCheckoutSession;
  if (basket?.payee === "organisation") {
    if (organisation?.chargesEnabled) {
      mutation = mutations.paymentInvoice;
    }
  } else {
    mutation = mutations.patientOrder;
  }

  return mutation;
}

const SHIPPING_ADDRESS_PREFIX = "shippingAddress";
// At the time of writing these fields are unique to the shipping address form
const SHIPPING_ADDRESS_FIELDS = ["id", "name", "line_1", "line_2", "city", "postcode", "country"];

function ShippingAddressAndFinalForm() {
  const { organisation } = useOrganisationContext();
  const { basket, updateBasketDetails } = useOrganisationBasketContext();

  const address1 = organisation?.primaryAddress;
  const address2 = organisation?.secondaryAddress;

  const addressesRef = useRef({
    address1: address1?.id,
    address2: address2?.id
  });

  addressesRef.current = {
    address1: address1?.id,
    address2: address2?.id
  };

  const [shippingAddressSelectValue, setShippingAddressSelectValue] = useState("address1");

  useEffect(() => {
    setShippingAddressSelectValue(
      Object.entries(addressesRef.current).find(
        ([k, v]) => v === basket?.shippingAddress?.id
      )?.[0] || "address1"
    );
  }, [setShippingAddressSelectValue, basket?.shippingAddress?.id]);

  const shippingCountries = useShippingCountries();

  const mutation = usePaymentFields({
    mutations: MUTATIONS_FOR_NEW_ADDRESS
  });

  const [shippingAddressMutation] = useMutation(SAVE_SHIPPING_ADDRESS_AT_CHECKOUT_MUTATION);

  const [submitMutation] = useMutation(mutation.mutation);

  const formApi = useForm({
    defaultValues: {
      [SHIPPING_ADDRESS_PREFIX]: {
        id: basket?.shippingAddress?.id || "",
        name: basket?.shippingAddress?.name || "",
        line_1: basket?.shippingAddress?.line1 || "",
        line_2: basket?.shippingAddress?.line2 || "",
        city: basket?.shippingAddress?.city || "",
        postcode: basket?.shippingAddress?.postcode || "",
        country: basket?.shippingAddress?.country?.isoCode || ""
      },
      organisation: organisation?.id || "",
      basket: basket?.id || "",
      addressForPatient: false,
      addressForOrganisation: true,
      addressForOrganisationPrimaryAddress: shippingAddressSelectValue === "address1"
    }
  });

  const haveExistingShippingAddress = !!basket?.shippingAddress;
  const [editShippingAddress, setEditShippingAddress] = useState(false);

  const showButtonsForShippingAddressForm = haveExistingShippingAddress && editShippingAddress;
  const shippingAddressFormEditable =
    showButtonsForShippingAddressForm || !haveExistingShippingAddress;

  const shippingAddressSubmitButtonRef = useRef();
  const checkoutSubmitButtonRef = useRef();

  const history = useHistory();

  const onSubmit = useCallback(
    formValues => {
      if (haveExistingShippingAddress && editShippingAddress) {
        const input = {
          ...formValues.shippingAddress,
          line1: formValues.shippingAddress.line_1,
          line2: formValues.shippingAddress.line_2,
          organisation: formValues.organisation,
          basket: basket.id
        };

        delete input.line_1;
        delete input.line_2;

        shippingAddressMutation({
          variables: {
            input
          }
        })
          .then(resp => {
            const errors = resp.data.saveShippingAddressAtCheckoutMutation.errors;
            if (errors.length) {
              errors.forEach(error => {
                formApi.setError(
                  error.field === "_All__"
                    ? NON_FIELD_ERROR_KEY
                    : `${SHIPPING_ADDRESS_PREFIX}.${error.field}`,
                  {
                    type: "custom",
                    message: error.messages[0]
                  }
                );
              });
              shippingAddressSubmitButtonRef.current?.setSuccessful(false);
              shippingAddressSubmitButtonRef.current?.setPending(false);
            } else {
              shippingAddressSubmitButtonRef.current?.setSuccessful(true);
              shippingAddressSubmitButtonRef.current?.setPending(false);
              formApi.reset({
                ...formValues
              });
              setEditShippingAddress(false);
            }
          })
          .catch(e => {
            console.log("Error updating shipping address", e);
          });
      } else {
        const input = {
          ...formValues,
          shippingAddress: JSON.stringify({
            ...formValues[SHIPPING_ADDRESS_PREFIX]
          })
        };

        submitMutation({
          variables: {
            input
          }
        })
          .then(resp => {
            const errors = resp.data[mutation.mutationName].errors;
            if (errors.length) {
              errors.forEach(error => {
                formApi.setError(
                  error.field === "_All__"
                    ? NON_FIELD_ERROR_KEY
                    : SHIPPING_ADDRESS_FIELDS.includes(error.field)
                    ? `${SHIPPING_ADDRESS_PREFIX}.${error.field}`
                    : error.field,
                  {
                    type: "custom",
                    message: error.messages[0]
                  }
                );

                checkoutSubmitButtonRef.current?.setSuccessful(false);
                checkoutSubmitButtonRef.current?.setPending(false);
              });
            } else {
              checkoutSubmitButtonRef.current?.setSuccessful(true);
              checkoutSubmitButtonRef.current?.setPending(false);
              mutation.handleSuccess?.({
                formResponseRelated: resp,
                history
              });
            }
          })
          .catch(e => {
            console.log("Error checking out", e);
          });
      }
    },
    [
      setEditShippingAddress,
      shippingAddressMutation,
      haveExistingShippingAddress,
      editShippingAddress,
      mutation,
      submitMutation,
      formApi,
      history,
      basket
    ]
  );

  useEffect(() => {
    if (basket && organisation) {
      formApi.reset({
        [SHIPPING_ADDRESS_PREFIX]: {
          id: basket?.shippingAddress?.id || "",
          name: basket?.shippingAddress?.name || "",
          line_1: basket?.shippingAddress?.line1 || "",
          line_2: basket?.shippingAddress?.line2 || "",
          city: basket?.shippingAddress?.city || "",
          postcode: basket?.shippingAddress?.postcode || "",
          country: basket?.shippingAddress?.country?.isoCode || ""
        },
        organisation: organisation?.id || "",
        basket: basket?.id || "",
        addressForPatient: false,
        addressForOrganisation: true,
        addressForOrganisationPrimaryAddress: shippingAddressSelectValue === "address1"
      });
    }
  }, [formApi, basket, organisation, shippingAddressSelectValue]);

  return (
    <FormProvider {...formApi}>
      <Stack as="form" gap={[20, 20, 40]} onSubmit={formApi.handleSubmit(onSubmit)}>
        <PartnerDashboardBox data-component-name="ShippingAddressForm">
          <Stack gap={[24, 24, 28]}>
            <Group alignItems="flex-start" justifyContent="space-between">
              <Stack gap={20}>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 28]}>
                  Organisation shipping address
                </Box>
                <NativeSelect
                  onChange={e => {
                    setShippingAddressSelectValue(e.target.value);
                    updateBasketDetails({
                      shippingAddress:
                        Object.entries(addressesRef.current).find(
                          ([k, v]) => k === e.target.value
                        )?.[1] || ""
                    });
                  }}
                  value={shippingAddressSelectValue}
                  data-component-name="OrganisationAddressSelect"
                >
                  <option value={"address1"}>Address 1</option>
                  <option value={"address2"}>Address 2</option>
                </NativeSelect>
              </Stack>
              {haveExistingShippingAddress && (
                <ButtonV2
                  color="dark"
                  size={["xs", "xs", "sm"]}
                  sx={{
                    color: "white"
                  }}
                  onClick={() => {
                    setEditShippingAddress(!editShippingAddress);
                  }}
                  type="button"
                >
                  edit address
                </ButtonV2>
              )}
            </Group>
          </Stack>
          <Stack gap={20}>
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.name`}
              Component={FloatingLabelInput}
              label="Name"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.line_1`}
              Component={FloatingLabelInput}
              label="Address line 1"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.line_2`}
              Component={FloatingLabelInput}
              label="Address line 2"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.city`}
              Component={FloatingLabelInput}
              label="City"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.postcode`}
              Component={FloatingLabelInput}
              label="Postcode"
              editable={shippingAddressFormEditable}
            />
            <ControlledFormField
              name={`${SHIPPING_ADDRESS_PREFIX}.country`}
              Component={NativeSelect}
              label=""
              readOnly={!shippingAddressFormEditable}
            >
              <option value="">Select country</option>
              {shippingCountries.map((country, idx) => (
                <option key={`${country.isoCode}-${idx}`} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </ControlledFormField>
          </Stack>
          {showButtonsForShippingAddressForm ? (
            <Center gap={20}>
              <ButtonV2
                type="button"
                color="red"
                rightIcon={<ChevronComponent />}
                onClick={() => {
                  formApi.reset();
                  setEditShippingAddress(false);
                }}
                size={["sm", "sm", "md"]}
              >
                Cancel
              </ButtonV2>
              <IndicativeButton
                rightIcon={<ChevronComponent />}
                ref={shippingAddressSubmitButtonRef}
                defaultColor="green"
                pendingColor="#0cf970"
                successColor="#2ecc71"
                failureColor="error"
                size={["sm", "sm", "md"]}
              >
                save
              </IndicativeButton>
            </Center>
          ) : null}
        </PartnerDashboardBox>
        {mutation.renderFields({
          canSubmit:
            !(haveExistingShippingAddress && editShippingAddress) &&
            (basket?.productLineItems.length > 0 || basket?.supplementLineItems.length > 0)
        })}
      </Stack>
    </FormProvider>
  );
}

export default function StockCheckoutForm() {
  return (
    <>
      <ShippingAddressAndFinalForm />
    </>
  );
}
