import Box from "components/Box";
import { theme } from "core/theme";
import Stack from "tpo/Stack";

export default function PartnerDashboardBox({
  children,
  gap,
  "data-component-name": dataComponentName
}) {
  return (
    <Box
      maxWidth={1020}
      mx="auto"
      bg="white"
      px={[20, 20, 40]}
      pt={theme.spacing.section.pt}
      pb={theme.spacing.section.pb}
      borderRadius={5}
      width="100%"
      data-component-name={dataComponentName}
    >
      <Stack gap={gap} mx="auto" maxWidth={760}>
        {children}
      </Stack>
    </Box>
  );
}

PartnerDashboardBox.defaultProps = {
  gap: 40
};
