import { createContext, forwardRef, useContext, useState } from "react";

import { Redirect, useParams } from "react-router-dom";

import { gql } from "@apollo/client";
import Circle from "components/Circle";
import CustomerPage from "components/CustomerPage";
import ResultGraph from "components/cot/ResultGraph";
import { ResultPresentationConsumer } from "contexts/ResultPresentation";
import { groupBy } from "lodash";
import { createGlobalStyle } from "styled-components";

import Box from "../components/Box";
import DataLoader from "../components/DataLoader";
import { ExternalTextLink, InternalTextLink } from "../components/Links";
import Text from "../components/Text";
import {
  ERROR_404_URL,
  getPatientTestBiomarkerUrl,
  getPatientTestResultUrl,
  getTestDatapointUrl,
  getTestResultUrl,
  getTestUrl
} from "../core/urls";
import {
  CHART_CONFIGURATION_FIELDS,
  DATAPOINT_FIELDS_TRUNCATED,
  RANGE_DEFINITION_FIELDS,
  RESULT_TYPE_FIELDS,
  USER_RESULT_FIELDS_TRUNCATED
} from "../graphql/tpo/results/types";
import Accordion, { AccordionContext } from "./Accordion";
import { Research, Symptoms, SymptomsList } from "./Accordions";
import { PanelBoxV2 } from "./Boxes";
import { ChartCardWrapper, GenesAllelesText } from "./ChartCards";
import ChartModal from "./ChartModal";
import ChevronComponent from "./Chevron";
import { CollapseableText } from "./CollapseableText";
import Group from "./Group";
import { ChartDetailLayout, ChartDetailModalLayout } from "./Layouts";
import Spacer from "./Spacer";
import {
  BiomarkersTested,
  GeneDatapointSummary,
  USER_DATAPOINT_QUERY,
  UserDatapointModelContent
} from "./UserDatapoint";
import { DatapointCharts } from "./UserDatapoints";
import { ResultCharts } from "./UserResults";
import { ViewerContext } from "./Viewer";

const RELATED_BIOMARKER_FIELDS = gql`
  fragment RelatedBiomarkerFields on UserDatapointType {
    id
    uploaded
    datapoint {
      id
      name
      category
      units
      description
      function
      reason
      chartType
      testProductSet {
        id
        paymentIsoCode
        productFamily {
          id
          name
          slug
        }
      }
    }
    riskAllele(userResultId: $userResultId)
    value
    interpretation
    rangeUsed {
      ...RangeDefinitionFields
    }
    chartConfiguration {
      ...ChartConfigurationFields
    }
    datapointState {
      id
      description
      research {
        id
        name
        url
      }
    }
    rangeUsed {
      ...RangeDefinitionFields
      minimum
      maximum
    }
  }
  ${RANGE_DEFINITION_FIELDS}
  ${CHART_CONFIGURATION_FIELDS}
`;

export const RelatedResultContext = createContext();

export function RelatedResult({ name, result, gap, py, leftFontSize, rightFontSize }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  const relatedResultContext = useContext(RelatedResultContext);

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" py={py} key={result.id}>
      {result.isHidden ? (
        <Text fontSize={leftFontSize}>{name}</Text>
      ) : relatedResultContext?.openModal ? (
        <Text
          color="anchorBlue"
          fontSize={leftFontSize}
          onClick={() => relatedResultContext.openModal(result)}
          cursor="pointer"
        >
          {name}
        </Text>
      ) : (
        <InternalTextLink
          href={
            !!clientId ? getPatientTestResultUrl(clientId, result.id) : getTestResultUrl(result.id)
          }
          fontSize={leftFontSize}
        >
          {name}
        </InternalTextLink>
      )}
      <ResultCharts result={result}>
        <ResultPresentationConsumer>
          {({ interpretationColor }) => {
            return (
              <Group alignItems="center" gap={10} justifyContent="end">
                <Text fontSize={rightFontSize} fontFamily="gilroyBold">
                  {result.chartConfiguration.interpretation}
                </Text>
                <Circle bg={interpretationColor} size={10} />
              </Group>
            );
          }}
        </ResultPresentationConsumer>
      </ResultCharts>
    </Box>
  );
}

RelatedResult.defaultProps = {
  leftFontSize: [16],
  rightFontSize: [16],
  py: [20]
};

export function RelatedResults({ results, py, gap, leftFontSize, rightFontSize }) {
  return results.map(result => (
    <RelatedResult
      key={result.id}
      gap={gap}
      result={result}
      name={result.resultState.name}
      py={py}
      leftFontSize={leftFontSize}
      rightFontSize={rightFontSize}
    />
  ));
}

RelatedResults.defaultProps = {
  py: ["8px", "8px", 16],
  gap: 20
};

export const USER_RESULT_QUERY = gql`
  query UserResultQuery($userId: ID, $userResultId: ID!) {
    userResult(userId: $userId, userResultId: $userResultId) {
      id
      isGenesRelated
      value
      freshValue
      interpretation
      rangeUsed {
        ...RangeDefinitionFields
      }
      availableDatapointRatio
      result {
        ...ResultTypeFields
      }
      thingsToDoCount
      thingsToAvoidCount
      peopleToSeeCount
      thingsToTakeCount
      peopleToSeeCount
      chartConfiguration {
        ...ChartConfigurationFields
      }
      resultState {
        id
        name
        description
      }
    }
  }
  ${RESULT_TYPE_FIELDS}
  ${RANGE_DEFINITION_FIELDS}
  ${CHART_CONFIGURATION_FIELDS}
`;

const TEST_BIOMARKERS_QUERY = gql`
  query TEST_BIOMARKERS_QUERY(
    $userId: ID
    $userResultId: ID
    $userTestId: ID
    $includeModifiers: Boolean
    $testProductFamilySlug: String
    $genesRelated: Boolean
    $excludeGenesRelated: Boolean
  ) {
    userDatapoints(
      userId: $userId
      userResultId: $userResultId
      userTestId: $userTestId
      includeModifiers: $includeModifiers
      testProductFamilySlug: $testProductFamilySlug
      genesRelated: $genesRelated
      excludeGenesRelated: $excludeGenesRelated
    ) {
      ...RelatedBiomarkerFields
    }
  }
  ${RELATED_BIOMARKER_FIELDS}
`;

function NotTestedBiomarker({ userDatapoint, py, px, fontSize, pl, pr }) {
  return (
    <Box display="flex" justifyContent="space-between" py={py} px={px} pl={pl} pr={pr}>
      <Box color="grey" fontSize={fontSize}>
        {userDatapoint.name}
      </Box>
      <InternalTextLink
        fontFamily="gilroyBold"
        fontSize={fontSize}
        marginCorrection={false}
        href={getTestUrl(
          userDatapoint.testProductSet[0].slug,
          userDatapoint.testProductSet[0].productFamily.slug
        )}
      >
        {userDatapoint.testProductSet[0].name}
      </InternalTextLink>
    </Box>
  );
}

NotTestedBiomarker.defaultProps = {
  py: 3,
  px: 4
};

const USER_RESULT_UNAVAILABLE_BIOMARKERS_QUERY = gql`
  query UserResultQuery($userId: ID, $userResultId: ID!) {
    userResult(userId: $userId, userResultId: $userResultId) {
      id
      unavailableDatapoints(considerGender: true) {
        ...DatapointFieldsTruncated
        testProductSet {
          id
          paymentIsoCode
          name
          slug
          productFamily {
            id
            name
            slug
          }
        }
      }
    }
  }
  ${DATAPOINT_FIELDS_TRUNCATED}
`;

export const UserDatapointSummary = forwardRef(
  ({ bg, userDatapoint, openModal, gap, py, px, fontSize }, ref) => {
    const viewerContext = useContext(ViewerContext);
    const clientId = viewerContext?.userId;

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={gap}
        py={py}
        key={userDatapoint.id}
        bg={bg}
        px={px}
        ref={ref}
      >
        {openModal ? (
          <Text
            fontSize={fontSize}
            color="anchorBlue"
            cursor="pointer"
            onClick={() => openModal(userDatapoint)}
            marginCorrection={false}
          >
            {userDatapoint.datapoint.name}
          </Text>
        ) : (
          <InternalTextLink
            href={
              clientId
                ? getPatientTestBiomarkerUrl(clientId, userDatapoint.id)
                : getTestDatapointUrl(userDatapoint.id)
            }
            fontSize={fontSize}
          >
            {userDatapoint.datapoint.name}
          </InternalTextLink>
        )}
        <DatapointCharts datapoint={userDatapoint}>
          <ResultPresentationConsumer>
            {({ chartProps, interpretationColor }) => {
              if (userDatapoint.datapoint.chartType === "GENES_ALLELES_CHART") {
                return <GenesAllelesText text={chartProps.type} color={chartProps.color} />;
              }
              return (
                <Text
                  fontSize={fontSize}
                  fontFamily="gilroyBold"
                  color={interpretationColor}
                  textAlign="right"
                >
                  {userDatapoint.chartConfiguration.interpretation}
                </Text>
              );
            }}
          </ResultPresentationConsumer>
        </DatapointCharts>
      </Box>
    );
  }
);

UserDatapointSummary.defaultProps = {
  py: 3,
  px: 4,
  fontSize: [15]
};

function RelatedTestedBiomarkers({ userDatapoints, openModal, headerProps, itemProps }) {
  const groupedUserDatapoints = groupBy(userDatapoints || [], userDatapoint => {
    return userDatapoint?.datapoint?.testProductSet?.[0]?.productFamily?.name;
  });

  if (!Object.keys(groupedUserDatapoints)?.length)
    return (
      <Box fontSize={[14, 14, 16]} px={[15, 15, 30]} pt={20} pb={2}>
        No datapoints tested
      </Box>
    );

  return Object.entries(groupedUserDatapoints).map(([familyName, userDatapoints]) => (
    <Box as="section" key={familyName}>
      <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]} {...headerProps}>
        {familyName}
      </Box>
      {userDatapoints.map(userDatapoint => (
        <UserDatapointSummary
          userDatapoint={userDatapoint}
          openModal={openModal}
          key={userDatapoint.id}
          fontSize={[14, 14, 16]}
          {...itemProps}
        />
      ))}
    </Box>
  ));
}

function RelatedNotTestedBiomarkers({ unavailableDatapoints }) {
  const datapoints = unavailableDatapoints?.filter(datapoint => datapoint.testProductSet.length);

  const groupedNotTestedUserDatapoints = groupBy(
    datapoints || [],
    userDatapoint => userDatapoint?.testProductSet?.[0]?.productFamily?.name
  );

  if (!Object.keys(groupedNotTestedUserDatapoints)?.length)
    return (
      <Box fontSize={[14, 14, 16]} px={[15, 15, 30]} pt={20} pb={2}>
        All available datapoints tested
      </Box>
    );

  return Object.entries(groupedNotTestedUserDatapoints).map(([familyName, userDatapoints]) => (
    <Box as="section" key={familyName}>
      <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]} px={[15, 15, 30]} pt={20} pb={2}>
        {familyName}
      </Box>
      {userDatapoints.map(userDatapoint => (
        <NotTestedBiomarker
          key={userDatapoint.id}
          userDatapoint={userDatapoint}
          fontSize={[14, 14, 16]}
          px={[20, 20, 40]}
          py={[2, 2, 20]}
        />
      ))}
    </Box>
  ));
}

function UserResultTexts({ userResult }) {
  return (
    <>
      {userResult.result?.description && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            What is it?
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {userResult.result?.description}
          </CollapseableText>
        </Box>
      )}
      {userResult.result?.function && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            What does it do?
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>{userResult.result?.function}</CollapseableText>
        </Box>
      )}
      {userResult.result?.reason && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            What measure it?
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>{userResult.result?.reason}</CollapseableText>
        </Box>
      )}
      {+userResult.value > 0.6 && userResult.resultState.description && (
        <Box>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
            Your interpretation
          </Box>
          <Box py={[1, 1, 2]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            {+userResult.value > 0.6 && userResult.resultState.description}
          </CollapseableText>
        </Box>
      )}
    </>
  );
}

const RELATED_RESULTS_QUERY = gql`
  query RelatedResults($userId: ID, $userResultId: ID!) {
    userResult(userId: $userId, userResultId: $userResultId) {
      id
      subresults {
        ...UserResultFieldsTruncated
        resultState {
          id
          name
        }
        chartConfiguration {
          ...ChartConfigurationFields
        }
      }
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;

function RelatedResultsAccordion({ openModal, userResult: { id: userResultId } }) {
  const viewerContext = useContext(ViewerContext);

  return (
    <Accordion.Item value="relatedResults">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Results
        </Text>
      </Accordion.Control>
      <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]} position="relative">
        <DataLoader
          query={RELATED_RESULTS_QUERY}
          variables={{
            userId: viewerContext?.userId,
            userResultId
          }}
          render={({ userResult }) => {
            const subResultDuplicateIdentifiers = userResult.subresults.map(
              subResult => subResult.result.id
            );
            const subResults =
              userResult?.subresults?.map(subResult => {
                return {
                  subResult,
                  duplicate:
                    subResultDuplicateIdentifiers.filter(id => id === subResult.result.id).length >
                    1
                };
              }) || [];

            return subResults.length ? (
              subResults.map(({ subResult: result, duplicate }) => (
                <RelatedResult
                  key={result.id}
                  name={duplicate ? result.resultState.name : result.result.name}
                  result={result}
                  py={[2, 2, 20]}
                  openModal={openModal}
                />
              ))
            ) : (
              <Box textAlign="left">No related results found</Box>
            );
          }}
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

function RelatedBiomarkersAccordion({ userResult, openModal }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  return (
    <Accordion.Item value="relatedBiomarkers">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Biomarkers
        </Text>
      </Accordion.Control>
      <Accordion.Panel padding={0}>
        <Accordion variant="default" chevronVariant="default" multiple size={15}>
          <Accordion.Item value="tested">
            <Accordion.Control
              p={15}
              pr={[30, 30, 35]}
              pl={[15, 15, 30]}
              chevronIcon={
                <AccordionContext.Consumer>
                  {({ openState }) => (
                    <ChevronComponent
                      fill="dark"
                      height={10}
                      width={10}
                      direction={openState.has("tested") ? "bottom" : "top"}
                      style={{
                        margin: 0
                      }}
                    />
                  )}
                </AccordionContext.Consumer>
              }
            >
              <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
                Tested
              </Text>
            </Accordion.Control>
            <Accordion.Panel p={0} pb={2} position="relative">
              <DataLoader
                query={TEST_BIOMARKERS_QUERY}
                variables={{
                  userId: clientId,
                  userResultId: userResult.id,
                  excludeGenesRelated: true
                }}
                render={({ userDatapoints }) => (
                  <RelatedTestedBiomarkers
                    userDatapoints={userDatapoints}
                    openModal={openModal}
                    headerProps={{
                      pt: 20,
                      pb: 2,
                      px: [15, 15, 30]
                    }}
                    itemProps={{
                      px: [20, 20, 40],
                      py: [2, 2, 20]
                    }}
                  />
                )}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="notTested" position="relative">
            <Accordion.Control
              p={15}
              pr={[30, 30, 35]}
              pl={[15, 15, 30]}
              chevronIcon={
                <AccordionContext.Consumer>
                  {({ openState }) => (
                    <ChevronComponent
                      fill="dark"
                      height={10}
                      width={10}
                      direction={openState.has("notTested") ? "bottom" : "top"}
                      style={{
                        margin: 0
                      }}
                    />
                  )}
                </AccordionContext.Consumer>
              }
            >
              <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={15}>
                Not Tested
              </Text>
            </Accordion.Control>
            <Accordion.Panel p={0} pb={2}>
              <DataLoader
                query={USER_RESULT_UNAVAILABLE_BIOMARKERS_QUERY}
                variables={{
                  userId: clientId,
                  userResultId: userResult.id
                }}
                render={({ userResult: { unavailableDatapoints } }) => (
                  <RelatedNotTestedBiomarkers
                    unavailableDatapoints={unavailableDatapoints}
                    px={20}
                  />
                )}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Accordion.Panel>
    </Accordion.Item>
  );
}

const RELATED_GENES_QUERY = gql`
  query RelatedGenesQuery($userId: ID, $userResultId: ID!) {
    relatedGenes: userDatapoints(
      userId: $userId
      userResultId: $userResultId
      genesRelated: true
      includeModifiers: true
    ) {
      ...RelatedBiomarkerFields
    }
  }
  ${RELATED_BIOMARKER_FIELDS}
`;

function RelatedGenesAccordion({ userResult: { id: userResultId }, openModal }) {
  const viewerContext = useContext(ViewerContext);

  return (
    <Accordion.Item value="relatedGenes">
      <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
        <Text marginCorrection={false} fontFamily="gilroyBold" fontSize={[16, 16, 18]}>
          Related Genes
        </Text>
      </Accordion.Control>
      <Accordion.Panel px={[20, 20, 40]} pb={[2, 2, 20]} position="relative">
        <DataLoader
          query={RELATED_GENES_QUERY}
          variables={{
            userId: viewerContext?.userId,
            userResultId
          }}
          render={({ relatedGenes }) =>
            relatedGenes?.length ? (
              relatedGenes?.map(userDatapoint => (
                <GeneDatapointSummary
                  userDatapoint={userDatapoint}
                  key={userDatapoint.id}
                  openModal={openModal}
                  py={[2, 2, 20]}
                />
              ))
            ) : (
              <Box>No related genes found</Box>
            )
          }
        />
      </Accordion.Panel>
    </Accordion.Item>
  );
}

const EXPERIENCED_SYMPTOMS_QUERY = gql`
  query ExperiencedSymptomsQuery($userId: ID, $userResultId: ID!) {
    userResult(userId: $userId, userResultId: $userResultId) {
      id
      experiencedSymptoms {
        id
        question {
          id
          text
        }
        answerText
        answerScore
      }
    }
  }
`;

const NOT_EXPERIENCED_SYMPTOMS_QUERY = gql`
  query NotExperiencedSymptomsQuery($userId: ID, $userResultId: ID!) {
    userResult(userId: $userId, userResultId: $userResultId) {
      id
      notExperiencedSymptoms {
        id
        question {
          id
          text
        }
        answerText
        answerScore
      }
    }
  }
`;

function RelatedSymptomQuestionsAccordion({ userResult: { id: userResultId } }) {
  const viewerContext = useContext(ViewerContext);
  return (
    <Symptoms
      experienced={
        <DataLoader
          query={EXPERIENCED_SYMPTOMS_QUERY}
          variables={{
            userId: viewerContext?.userId,
            userResultId
          }}
          render={({ userResult }) =>
            userResult?.experiencedSymptoms?.length ? (
              <SymptomsList questions={userResult?.experiencedSymptoms} />
            ) : (
              <Box pt={[2, 2, 20]}>No experienced symptoms found</Box>
            )
          }
        />
      }
      notExperienced={
        <DataLoader
          query={NOT_EXPERIENCED_SYMPTOMS_QUERY}
          variables={{
            userId: viewerContext?.userId,
            userResultId
          }}
          render={({ userResult }) =>
            userResult?.notExperiencedSymptoms?.length ? (
              <SymptomsList questions={userResult?.notExperiencedSymptoms} />
            ) : (
              <Box pt={[2, 2, 20]}>N/A</Box>
            )
          }
        />
      }
    />
  );
}

const RELATED_RESEARCH_QUERY = gql`
  query RelatedResearchQuery($userId: ID, $userResultId: ID!) {
    userResult(userId: $userId, userResultId: $userResultId) {
      id
      research {
        id
        name
        url
      }
    }
  }
`;

function RelatedResearchAccordion({ userResult: { id: userResultId } }) {
  const viewerContext = useContext(ViewerContext);
  return (
    <Research
      research={
        <DataLoader
          query={RELATED_RESEARCH_QUERY}
          variables={{
            userId: viewerContext?.userId,
            userResultId
          }}
          render={({ userResult }) =>
            userResult?.research?.length ? (
              userResult?.research?.map(research => (
                <ExternalTextLink
                  display="block"
                  key={research.id}
                  fontSize={16}
                  href={research.url}
                  py={[2, 2, 20]}
                >
                  {research.name}
                </ExternalTextLink>
              ))
            ) : (
              <Box>No research found</Box>
            )
          }
        />
      }
    />
  );
}

export function UserResultModelContent({ userResult, isGenesTest }) {
  return (
    <ChartDetailModalLayout
      chart={
        <>
          <ChartCardWrapper
            title={userResult.chartConfiguration.displayName || userResult.resultState.name}
            chart={<ResultCharts result={userResult} />}
            showArrowCircle={false}
            showCotIcon={userResult.isGenesRelated ? false : true}
            cotChart={
              <ResultGraph userResult={userResult} chartId={`result-${userResult.id}-modal`} />
            }
          />
          <Spacer py={[20]} />
          <BiomarkersTested
            chartProps={{
              backgroundColor: "haze",
              height: 5,
              showText: false,
              showChevron: false,
              enclosed: false,
              foregroundColor: "brand"
            }}
            value={Math.round(userResult.availableDatapointRatio || 0) * 100}
          />
        </>
      }
      text={<UserResultTexts userResult={userResult} />}
      accordion={
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          size={20}
          gap={[10, 10, 20]}
        >
          <RelatedResultsAccordion userResult={userResult} />
          {!isGenesTest && <RelatedBiomarkersAccordion userResult={userResult} />}
          <RelatedGenesAccordion userResult={userResult} />
          <RelatedSymptomQuestionsAccordion userResult={userResult} />
          <RelatedResearchAccordion userResult={userResult} />
        </Accordion>
      }
    />
  );
}
function UserResultContent({ userResult, openModal }) {
  return (
    <ChartDetailLayout
      chart={
        <>
          <ChartCardWrapper
            title={userResult.chartConfiguration.displayName || userResult.resultState.name}
            chart={<ResultCharts result={userResult} />}
            showArrowCircle={false}
            showCotIcon={userResult.isGenesRelated ? false : true}
            cotChart={<ResultGraph userResult={userResult} chartId={`result-${userResult.id}`} />}
          />
          <Spacer py={[20]} />
          <BiomarkersTested
            chartProps={{
              backgroundColor: "haze",
              height: 5,
              showText: false,
              showChevron: false,
              enclosed: false,
              foregroundColor: "brand"
            }}
            value={Math.round(userResult.availableDatapointRatio || 0) * 100}
          />
        </>
      }
      text={<UserResultTexts userResult={userResult} />}
      accordion={
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          gap={[10, 10, 20]}
          size={20}
        >
          <RelatedResultContext.Provider
            value={{
              openModal: result => {
                openModal({
                  type: "results",
                  item: result
                });
              }
            }}
          >
            <RelatedResultsAccordion userResult={userResult} />
          </RelatedResultContext.Provider>
          {!userResult.isGenesRelated && (
            <RelatedBiomarkersAccordion
              userResult={userResult}
              openModal={datapoint => {
                openModal({
                  type: "datapoints",
                  item: datapoint
                });
              }}
            />
          )}
          <RelatedGenesAccordion
            openModal={datapoint => {
              openModal({
                type: "datapoints",
                item: datapoint
              });
            }}
            userResult={userResult}
          />
          <RelatedSymptomQuestionsAccordion userResult={userResult} />
          <RelatedResearchAccordion userResult={userResult} />
        </Accordion>
      }
    />
  );
}

function ModalContent({ type, item }) {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;
  if (type === "results") {
    return (
      <DataLoader
        query={USER_RESULT_QUERY}
        variables={{
          userId: clientId,
          userResultId: item.id
        }}
        render={({ userResult }) => <UserResultModelContent userResult={userResult} />}
      />
    );
  } else if (type === "datapoints") {
    return (
      <DataLoader
        query={USER_DATAPOINT_QUERY}
        variables={{
          userDatapointId: item.id,
          userId: clientId
        }}
        render={({ userDatapoint }) => <UserDatapointModelContent userDatapoint={userDatapoint} />}
      />
    );
  }
  return null;
}

const GlobalStyle = createGlobalStyle`
  .PoweredByRegenerusBanner svg {
    fill: black;
  }
`;

function UserResult() {
  const viewerContext = useContext(ViewerContext);
  const clientId = viewerContext?.userId;

  const { userResultId } = useParams();
  const isPartnerView = !!clientId;

  const [modalContent, setModalContent] = useState();

  return (
    <DataLoader
      query={USER_RESULT_QUERY}
      variables={{
        userId: clientId,
        userResultId
      }}
      render={({ userResult }) => {
        if (!userResult) {
          return <Redirect to={ERROR_404_URL} />;
        }

        return (
          <ViewerContext.Provider
            value={{
              userId: clientId
            }}
          >
            <CustomerPage bg="haze">
              <Box pt={94} />
              <GlobalStyle />
              <UserResultContent
                clientId={clientId}
                isPartnerView={isPartnerView}
                userResult={userResult}
                openModal={obj => {
                  setModalContent(obj);
                }}
              />
              <ChartModal close={() => setModalContent()} show={!!modalContent}>
                {!!modalContent && (
                  <ModalContent type={modalContent.type} item={modalContent.item} />
                )}
              </ChartModal>
            </CustomerPage>
          </ViewerContext.Provider>
        );
      }}
    />
  );
}

export const ResultChartCard = forwardRef(({ userResult, onClick }, ref) => (
  <PanelBoxV2
    maxWidth={760}
    outer={{
      bg: "white",
      borderRadius: 5,
      cursor: "pointer",
      px: 20,
      py: 20
    }}
    ref={ref}
  >
    <ChartCardWrapper
      onClick={onClick}
      title={userResult.chartConfiguration.displayName || userResult.resultState.name}
      chart={<ResultCharts result={userResult} />}
      showCotIcon={userResult.isGenesRelated ? false : true}
      cotChart={<ResultGraph userResult={userResult} chartId={`result-${userResult.id}`} />}
    />
    <Spacer py={[2]} />
  </PanelBoxV2>
));

export const ResultChartWithBiomarkersTested = forwardRef(({ userResult, onClick }, ref) => (
  <PanelBoxV2
    maxWidth={760}
    outer={{
      bg: "white",
      borderRadius: 5,
      cursor: "pointer",
      px: [20],
      py: [20]
    }}
    ref={ref}
  >
    <ChartCardWrapper
      onClick={onClick}
      title={userResult.chartConfiguration.displayName || userResult.resultState.name}
      chart={<ResultCharts result={userResult} />}
      showCotIcon={userResult.isGenesRelated ? false : true}
      cotChart={<ResultGraph userResult={userResult} chartId={`result-${userResult.id}`} />}
    />
    <Spacer py={[20]} />
    <BiomarkersTested
      chartProps={{
        backgroundColor: "haze",
        height: 5,
        showText: false,
        showChevron: false,
        enclosed: false,
        foregroundColor: "brand"
      }}
      value={Math.round((userResult.availableDatapointRatio || 0) * 100)}
    />
  </PanelBoxV2>
));

function UserResultPage() {
  const { userResultId } = useParams();

  /**
   * The key prop usage here ensures this component
   * always re-mounts for a new userResultId.
   */

  return <UserResult key={userResultId} />;
}

export default UserResultPage;
