import { useCallback, useMemo } from "react";

import { useMutation } from "@apollo/client";
import ConnectedBadge from "components/organisations/shared/ui/ConnectedBadge";
import DisconnectedBadge from "components/organisations/shared/ui/DisconnectedBadge";
import InvitedBadge from "components/organisations/shared/ui/InvitedBadge";
import NotLinkedBadge from "components/organisations/shared/ui/NotLinkedBadge";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import {
  REQUEST_ACCESS_TO_RESULTS_MUTATION,
  RESEND_REQUEST_TO_ACCESS_RESULTS_MUTATION
} from "graphql/organisations/mutations";
import { CONNECTED_USERS_QUERY } from "graphql/organisations/queries/patients";
import useDisclosure from "hooks/useDisclosure";
import Center from "tpo/Center";
import Modal from "tpo/Modal";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

import { getClientManagementTabUrl } from "../../../../core/urls";
import { useDataLoader } from "../../../../hooks/useDataLoader";
import { PanelBoxV2 } from "../../../../tpo/Boxes";
import ChevronComponent from "../../../../tpo/Chevron";
import Group from "../../../../tpo/Group";
import { List, ListContext } from "../../../../tpo/List";
import SimpleTable from "../../../../tpo/SimpleTable";
import Spacer from "../../../../tpo/Spacer";
import Table, { useTableControls } from "../../../../tpo/Table";
import Box from "../../../Box";
import { ExternalTextLink } from "../../../Links";
import Text from "../../../Text";

const renderers = {
  personalDetails: ({ email, platformUserProfile }) => {
    return (
      <Box display="flex" flexDirection="column" gap={10}>
        {!!(platformUserProfile?.user?.firstName && platformUserProfile?.user?.lastName) && (
          <Text
            fontFamily="gilroyBold"
            fontSize={[14, 14, 16]}
          >{`${platformUserProfile?.user?.firstName}, ${platformUserProfile?.user?.lastName}`}</Text>
        )}
        {!!email && <Text fontSize={[14, 14, 16]}>{email}</Text>}
      </Box>
    );
  },
  date: ({ created }) => {
    const date = new Date(created);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
  },
  status: ({ relatedResultsAccessLinkId, granted }) => {
    if (granted) return <ConnectedBadge />;

    if (relatedResultsAccessLinkId) {
      if (granted === false) {
        return <DisconnectedBadge />;
      } else {
        return <InvitedBadge />;
      }
    }
    return <NotLinkedBadge />;
  },
  results: ({ id, platformUserProfile, relatedResultsAccessLinkId, granted }) => {
    return (
      <StatusBasedButton
        id={id}
        platformUserProfile={platformUserProfile}
        relatedResultsAccessLinkId={relatedResultsAccessLinkId}
        granted={granted}
      />
    );
  }
};

function ResendInvite({ id }) {
  const { organisation } = useOrganisationContext();

  const [resendInviteMutation] = useMutation(RESEND_REQUEST_TO_ACCESS_RESULTS_MUTATION, {
    variables: {
      input: {
        organisation: organisation?.id,
        id
      }
    }
  });

  const resendInvite = useCallback(() => {
    resendInviteMutation().catch(e => console.log("Error encountered resending invite", e));
  }, [resendInviteMutation]);

  return (
    <ButtonV2
      color="dark"
      rightIcon={<ChevronComponent />}
      onClick={resendInvite}
      size={["sm", "sm", "md"]}
    >
      Resend
    </ButtonV2>
  );
}

function RequestAccessToResults({ id, platformUserProfile }) {
  const [show, { open, close }] = useDisclosure();

  const { organisation } = useOrganisationContext();

  const [requestAccessMutation] = useMutation(REQUEST_ACCESS_TO_RESULTS_MUTATION, {
    update: (cache, { data }) => {
      if (data?.requestAccessToResultsMutation?.errors.length === 0) {
        cache.modify({
          id: `OrganisationPatientType:${id}`,
          fields: {
            relatedResultsAccessLinkId: () => {
              return data?.requestAccessToResultsMutation?.resultsAccessLink?.id;
            },
            granted: () => {
              return null;
            }
          }
        });
      }
    }
  });

  const requestAccess = useCallback(() => {
    requestAccessMutation({
      variables: {
        input: {
          // NB - organisation field is only for perm checking on server side
          organisation: organisation?.id,
          platformUserProfile: platformUserProfile?.pk
        }
      }
    }).catch(e => console.log("Error encountered requesting access", e));
  }, [requestAccessMutation, platformUserProfile?.pk, organisation]);

  return (
    <>
      <Modal
        mode={["fullScreen", "fullScreen", "centered"]}
        maxWidth={1000}
        bg="white"
        closeButton
        headerProps={{
          p: [20, 20, 40]
        }}
        show={show}
        close={close}
        data-component-name="RequestAccessToResultsModal"
      >
        <Stack gap={40} mx="auto" maxWidth={720} width="100%" pb={[60, 60, 80]} px={20}>
          <Stack gap={20}>
            <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
              Results access
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
              You are about to request access to a patients records. They will be sent an invitation
              to connect and will need to grant you permission before you can see their profile and
              results.
            </Box>
          </Stack>
          <Center gap={20}>
            <ButtonV2 color="red" size={["sm", "sm", "md"]} onClick={close}>
              cancel
            </ButtonV2>
            <ButtonV2
              color="green"
              size={["sm", "sm", "md"]}
              onClick={() => {
                requestAccess();
                close();
              }}
            >
              send request email
            </ButtonV2>
          </Center>
        </Stack>
      </Modal>
      <ButtonV2
        rightIcon={<ChevronComponent />}
        color="dark"
        size={["sm", "sm", "md"]}
        onClick={open}
      >
        request access
      </ButtonV2>
    </>
  );
}

function StatusBasedButton({ id, platformUserProfile, relatedResultsAccessLinkId, granted }) {
  const profileBtn = (
    <ButtonV2
      as={ExternalTextLink}
      rightIcon={<ChevronComponent />}
      href={getClientManagementTabUrl(platformUserProfile?.user?.id)}
      color="green"
      newTab
      size={["sm", "sm", "md"]}
      underline={false}
    >
      profile
    </ButtonV2>
  );

  const resendBtn = <ResendInvite id={relatedResultsAccessLinkId} />;

  if (granted) {
    // if the practitioner is viewing themselves no RAL is needed
    return profileBtn;
  }

  if (relatedResultsAccessLinkId) {
    if (granted === false) {
      return resendBtn;
    } else {
      return resendBtn;
    }
  }

  return (
    // request access to results
    // so creating an RAL linking the partner and the platform user profile
    // i.e. not involving the organisation
    <RequestAccessToResults id={id} platformUserProfile={platformUserProfile} />
  );
}

function ConnectedUserCard({
  platformUserProfile,
  email,
  granted,
  id,
  status,
  created,
  relatedResultsAccessLinkId
}) {
  return (
    <Box
      pt={2}
      pb={20}
      display="flex"
      flexDirection="column"
      gap={20}
      borderColor="haze"
      borderBottomStyle="solid"
      borderWidth={1}
    >
      {renderers.personalDetails({ email, platformUserProfile })}
      <Group alignItems="center" justifyContent="space-between">
        <Box fontFamily="gilroyMedium" fontSize={16} lineHeight="150%">
          {renderers.date({ created })}
        </Box>
      </Group>
      <Group>{renderers.status({ relatedResultsAccessLinkId, granted })}</Group>
      <Box display="flex" justifyContent="flex-start">
        {renderers.results({
          id,
          platformUserProfile,
          relatedResultsAccessLinkId,
          granted
        })}
      </Box>
    </Box>
  );
}

const SORT_OPTIONS = [
  {
    label: "Date order (Newest - Oldest)",
    value: "-created"
  },
  {
    label: "Date order (Oldest - Newest)",
    value: "created"
  },
  {
    label: "Status (Ascending)",
    value: "status"
  },
  {
    label: "Status (Descending)",
    value: "-status"
  }
];

function ConnectedUsers() {
  const { organisation } = useOrganisationContext();

  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { data, loading } = useDataLoader({
    query: CONNECTED_USERS_QUERY,
    variables: {
      page,
      orderBy: sort,
      search: debouncedSearch,
      organisationId: organisation?.id
    }
  });

  const columns = useMemo(
    () => [
      {
        id: "email",
        label: "Name / Email",
        accessor: ({ email, platformUserProfile }) => {
          return renderers.personalDetails({ email, platformUserProfile });
        }
      },
      {
        id: "created",
        accessor: ({ created }) => {
          return renderers.date({ created });
        },
        label: ""
      },
      {
        id: "status",
        accessor: ({ relatedResultsAccessLinkId, granted }) => {
          return renderers.status({ relatedResultsAccessLinkId, granted });
        },
        label: "Status"
      },
      {
        id: "results",
        accessor: row => <Group justifyContent="flex-end">{renderers.results(row)}</Group>,
        label: "",
        align: "center",
        width: "25%"
      }
    ],
    []
  );

  const items = data?.connectedUsersPagination?.items;
  const pageInfo = data?.connectedUsersPagination?.pageInfo;

  return (
    <PanelBoxV2 maxWidth={1280}>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: 20,
          borderRadius: 5,
          "data-component-name": "ConnectedUsers"
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} lineHeight="130%">
          Connected users
        </Box>
        <Spacer py={[2, 2, 20]} />
        <SimpleTable
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          sortMenuOpen={sortMenuOpen}
          setSortMenuOpen={setSortMenuOpen}
          pageInfo={pageInfo}
          setPage={setPage}
          sortingOptions={SORT_OPTIONS}
          table={
            <>
              <Box display={["none", "none", "block"]}>
                <Table columns={columns} loading={loading} rows={items} />
              </Box>
              <List items={items} loading={loading} display={["block", "block", "none"]}>
                <ListContext.Consumer>
                  {items => items?.map(item => <ConnectedUserCard key={item.id} {...item} />)}
                </ListContext.Consumer>
              </List>
            </>
          }
        />
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

export default ConnectedUsers;
